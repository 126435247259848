.containerColab {
  display: none;
}

.containerColab.active {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	align-content: flex-start;
}

.containerColab.active a {
  width: 100px;
  height: 100px;
}

.container-cloud-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  scale: 1.2;
  width: 200px;
  height: 150px;
}

.cloud-menu {
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: #ffffff 65px -15px 0 -5px, #ffffff 25px -25px, #ffffff 30px 10px,
    #ffffff 60px 15px 0 -10px, #ffffff 85px 5px 0 -5px;
  opacity: 0.9;
  z-index: 1;
}

.container-cloud-menu a {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.container-cloud-menu img {
  height:50%;
  margin-right: 80%;
}

.floating { 
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}


