.sunMoonContainer {
	display: flex;
	height: 1200px;
	width: 1000px;
	/* width: 200vw; */
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	transform-origin: center center;
	margin-top: 50%;
	transition: transform 2s linear;
	z-index: 0;
}

.moon {
	height: 100px;
	width: 100px;
	box-shadow: -15px 15px 0 5px white;
	border-radius: 50%;
}

.sun {
	width: 100px;
	height: 100px;
	background: rgb(255, 179, 37);
	border-radius: 50%;
}

.astra {
	position: absolute;
	width: 1000px;
	height: 1200px;
	transform: translate(-50%,-0%);
	left: 50%;
	bottom: 0%;
	display: flex;
	justify-content: center;
}
