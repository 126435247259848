.filtBright
{
  filter:brightness(10%);
}
.filtBright2
{
  filter:brightness(20%);
}

#ert
{
  transition : filter 4s;
}

.reportTab
{
  width: 170px;
  height: 70px;
  background-color: black;
  position: fixed;
  bottom: 5px;
  left : 50%;
  transform: translate(-50%);
  padding : 5px;

}

.tinyInfo
{
  height:10px;
  width: 100%;
}

.buildingTemp
{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10px;
  justify-content: space-between;
}

.environment {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(8, 1fr);
  z-index:0;
}

.groundLevel
{
  grid-row-start: 3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.background {
	width: 100vw;
	height: 100vh;
  position: fixed;
}

.appContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}

.headerContainer {
	width: 100%;
  z-index : 12;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mainContent {
	/* height: 90vh; */
	width: 100vw;
	z-index: 11;
	display: flex;
	justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  margin-top: 10%;
}

.footerContainer {

  height: 20px;
}

#b
{
  font-weight: bold;
}

#c
{
  text-align: center;
}

#j
{
  text-align: justify;
}

@media screen and (min-width : 780px)
{

  .mainContent
  {
    margin-top: -1%;
  }

  .prodPage
  {
    margin-top: -5%;
  }
  
}

@media screen and (min-width : 460px) and (min-width < 780px)
{
  .mainContent
  {
    margin-top: 10%;
  }  

  .prodPage
  {
    margin-top: 10%;
  }
}

html {
  overflow-x: hidden;
}