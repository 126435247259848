.gradient-bg {
	width: 100vw;
	height: 100vh;
	background-color:black;
	position: fixed;
	transition : background-color 5s linear;
	overflow: hidden;
}

.bg-hills
{
	position: absolute;
	bottom: 0;
	width: 100%;
	height:100%;
	z-index:1;
}

.hill
{
	transition: fill 5s, filter 5s;
}

.nightSky
{
	position: absolute;
	width: 100vw;
	height: 100vh;
	transition : opacity 5s;
}

.star
{
	position: absolute;
	width: 1px;
	height: 1px;
	background-color: white;
	animation-name: sparkle;
	animation-duration: 200ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

}

@keyframes sparkle
{
	0%{opacity : 0.75}
	50%{opacity : 1}
}

