.box
{
    min-width: 110px;
    max-width: 800px;

    width: 800px;
    height: auto;
    margin: 20px;
    border-style: solid;
    border-width: 20px;
    border-color: rgb(20, 20, 20);
    border-radius: 1%;
    background-color: rgb(20, 20, 20);
    font-size: smaller;
    color:orange;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.formbox {
    min-width: 110px;
    max-width: 800px;

    width: 800px;
    height: auto;
    margin: 20px;
    border-style: solid;
    border-width: 20px;
    border-color: rgb(20, 20, 20);
    border-radius: 1%;
    background-color: rgb(20, 20, 20);
    font-size: smaller;
    color:orange;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.contentform {
    height: inherit;
	width: inherit;
    display: flex;
	justify-content: center;
	align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 3160px;
}

.text
{
    height: 45%;
}

.boxImageContainer
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.quiBoxImageContainer
{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* height: 45%; */
    width: 100%;
}

.quiBoxImageContainer img
{
   width: 100%;
}

.minibox
{
    width: 20%;
    min-width: 160px;
    max-width: 340px;
    height: 200px;
    margin: 20px;
    overflow : hidden;
    border-style: solid;
    border-width: 20px;
    border-color:rgb(20, 20, 20);
    border-radius: 1%;
    background-color: rgb(20, 20, 20);
    font-size: smaller;
    display: flex;
    flex-direction: column;
    color:orange;
    cursor: pointer;
    transition: transform 0.5s;
}

.minibox:hover
{
    transform: scale(1.05);
}

.miniboxImgContainer
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.miniboxIcon
{
    width: 90%;
    
}

.miniboxIconImg {
    width: 65%;
	position: relative;
	padding: 8px;
	height: 50%;
	top: -17%;
    overflow: hidden;
    opacity: 0.9;
}

.textBox
{
    font-size: 10px;
    text-align: center;
}

.boxImage
{
    /* width: 75%; */
    /* height: 100%; */
    max-height: 400px;
    min-width: 110px;
}

/* .elsys{
    overflox:
} */

.content 
{
	height: inherit;
	width: inherit;
    display: flex;
	justify-content: center;
	align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 3160px;
}

.prodPage
{
    width: auto;
    margin: 20px;
    /* height: 800px; */
    background-color: rgb(10,10,10);
    /* position: absolute; */
    border-radius: 1%;
    min-width: 110px;
    max-width: 1920px;
    /* min-height: 1283px; */
    /* background-color: black; */
    flex-direction: column;
    margin-top: 50px;
    background: linear-gradient(121deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 28%, rgba(255,93,0,1) 28%, rgba(0,0,0,1) 37%, rgba(255,93,0,1) 37%, rgba(0,0,0,1) 48%, rgba(255,93,0,1) 48%, rgba(0,0,0,1) 65%);
}

.prod-bg
{
    display: grid;
    grid-template-columns: repeat(auto-fill, 0.5%);
    grid-template-rows: repeat(auto-fill, 0.5%);
    position: absolute;
}

.o
{
    opacity:0.2;
}

.column
{
    grid-row-start: 1;
    grid-row-end: 204;
    border-radius: 15%;
}

.row
{
    grid-column-start: 1;
    grid-column-end: 194;
    border-radius: 15%;
}

.red{
    background-color: red;
}

.orange
{
    background-color: orange;
}

.gold{
    background-color: gold;
}

.white{
    background-color: white;
}

.prodPage-top
{
    display: grid;
    grid-template-columns: 90% 10%;
    z-index: 1;
}

.prodTitle
{
    color: orange;
    text-align: center;
    padding-left: 10%;
    z-index: 1;
    text-transform: lowercase;
    font-style: italic;
    font-size: 3vh;
    overflow: hidden;
    height: 100%;
    /* line-height: 2vh; */
    margin-top: 1vw;
    filter: drop-shadow(5px 5px 5px black);

}
.prodPage-flex
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
    max-width: 1920px;
    flex-wrap: wrap;
    flex-direction: row;
    z-index: 1;
}

.prodPage-footer
{
    height: 20px;
}

.x{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index:1
}

.x img
{
    width: 50%;
    cursor:pointer;
}

.txt1
{
    color:orange;
    padding: 20px;
    width: auto;
    min-width: 90px;
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0);
    text-align: justify;
    z-index: 1;
    background-color: rgb(20,20,20);
    margin: 20px;
    /* border: 10px solid orange; */
    /* font-size: 1.1vw; */
}

.txt2
{
    color:orange;
    overflow: hidden;
    padding: 20px;
    width: auto;
    min-width: 90px;
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0);
    text-align: justify;
    z-index:1;
    background-color: rgb(20,20,20);
    margin: 20px;
    /* border: 10px solid gold; */
}

.img1
{
    display: flex;
    width: 70%;
    justify-content: center;
    border-radius: 1%;
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0);
    min-width: 100px;
    max-width: 540px;
    z-index:1;
}

.img2
{
    display: flex;
    width: 70%;
    justify-content: center;
    border-radius: 1%;
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0);
    min-width: 100px;
    z-index:1;
}

.img1 img
{
    width: 100%;
}

.img2 img
{
    width: 100%;
}

/* form part */

.contact.box {
    display: block;
}

input {
    margin-bottom: 20px;
    width: 100%;
    height: 30px;
}

textarea {
    height: 100px;
    width: 100%;
}

#send {
    margin-top: 10px;
    background-color: orange;
    width: 150px;
    height: 40px;
    cursor:pointer;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

#send:hover {
    background-color: white;
    color: orange;
}

@media screen and (min-width: 780px) {
    .box {
        font-size:medium;

        height: 800px;
    }

    .prodPage {
        margin: 4px;

    }

    .textBox {
        font-size:12px;
    }

    .contentform {
        flex-direction: column;
    }

    .minibox
    {
        width: 20%;
        min-width: 350px;
        max-width: 340px;
        height: 300px;
        margin: 20px;
        overflow : hidden;
        border-style: solid;
        border-width: 20px;
        border-color:rgb(20, 20, 20);
        border-radius: 1%;
        background-color: rgb(20, 20, 20);
        font-size: smaller;
        display: flex;
        flex-direction: column;
        color:orange;
        cursor: pointer;
    }

    .img1 {
        width: 40%;
    }

    .txt1 {
        width: 40%;
    }

    .img2 {
        width: 40%;
    }

    .txt2 {
        width: 40%;
    }


}

@media screen and (min-width: 400px) and (max-width: 779px) {
    .box {
        font-size:small;
        height: auto;
    }

}