.buildingStructure
{
    position:absolute;
    display: flex;
    justify-items: flex-start;
    overflow:hidden;
}

.buildPart
{
    width: 33%;
    height: 100%;
}

.buildingColors {
	width: 100%;
	height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition : filter 8s;

}

.city {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
    z-index:1;
}

.cityLights {
	position: absolute;
	width: 300%;
	height: 400px;
	left: 50%;
	transform: translate(-50%,0%);
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    z-index:4;
}

.lamp{
    width: 2px;
    height: 2px;
    background-color: "orange";
    transition : box-shadow 4s, filter 4s, opacity 4s;
    z-index: 1;
    opacity : 0.8;
}

.off
{
    box-shadow: 0px 0px 0px 0px transparent;
    opacity : 0;
}


.building{
    z-index:3;
}


.windows {
	width: 100%;
	height: 75%;
	position: relative;
	display: flex;
	top: -100%;
	justify-content: flex-start;
	align-items: flex-end;
	flex-wrap: wrap;
    overflow: hidden;
}

.nightWindows {
    height: 100%;
    position: relative;
    left: 0px;
    top: -200%;
    width: 100%;
    overflow: hidden;
}

.buildingWindow
{
    background-color: rgba(255,255,255, 0.0);
    width: 3px;
    height: 2px;
    transition: background-color 4s;
    z-index: 1;
}

.nightWindow
{
    width: 2px;
    height: 2px;
    position: relative;
}
.buildingTop
{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    top:-30px;
    transition: filter 5s;
}

#ImageElsysHouse_rooftop {
	position: relative;
	width: 15px;
	height: 15px;
	transition: filter 5s;
}
