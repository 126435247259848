.split-ground {
    width: 100vw;
    grid-row: 4;
    display: grid;
    grid-template-rows: 1fr 1fr;
    z-index:2;
    transition: filter 8s;
}

.ground {
    background-color: rgb(121,139,7);
    height: 50px;
    z-index: 2;
}

.dirt {
    background-color: rgb(64, 27, 27); 
    z-index: 2;
}