

.forest {
	width: 100%;
	height: 53px;
	z-index: 4;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: flex-end;
	overflow: hidden;
	transition: filter 8s;
}

.tree-circle3 {
	position: absolute;
	border-radius: 50%;
	width: 75%;
	height: 32%;
	bottom: 4%;
	background: #0c6900;
	z-index: 1;
}

.tree-circle2 {
	position: absolute;
	border-radius: 50%;
	width: 70%;
	height: 32%;
	bottom: 16%;
	background: #097a12;
	z-index: 2;

}

.tree-circle1 {
	position: absolute;
	border-radius: 50%;
	width: 45%;
	height: 18%;
	bottom: 39%;
	background: #048119;
	z-index: 3;

}

.trunk {
	position: absolute;
	width: 15%;
	height: 40%;
	background: #502400;
	z-index: 0;
	bottom: -10%;

}

.tree {
    display: flex;
	width: 20px;
	height: 50px;
    justify-content: center;
    align-items: center;
	transform-origin: bottom center;
	
}

