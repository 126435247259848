.header {
	width: 100%;
    position:fixed;
    top: 0;
}

.top-header {
    width: 100%;
    /* height: 12%; */
    display: grid;
    grid-template-columns: 75% 25%;
    background-color: rgb(44, 44, 44);
}

.bot-header {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.bot-header.responsive {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.931);
}

.align-img {
    display: flex;
    align-items: center;
    justify-content: center;

    grid-column: 1;
}

.container-bars {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 2;
    grid-row: 1;

}

.header img {
    width: 200px;

    min-width: 10px;
}

.text-container {
    width: 100px;
}

.bars-aligner {
    display: block;

    min-width: 2px;
    min-height: 2px;
}

.bar {
    width: 50px;
    height: 10px;
    background-color: orangered;
    border-radius: 10%;
    margin: 10px;

}

.text-container.responsive {
    display: block;
    align-items: center;
    justify-content: center;

    border-radius: 1%;
    width: 100vw;
    height: 100vh;
}

.button.active {
    background-color: rgb(44, 44, 44);
    border-radius: 2%;
    height: 10%;
    margin: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}

.button.active:hover {
    background-color: grey;
}

.text-container.responsive p {
    display: block;
    color: rgb(255, 115, 0);
    font-size: 100%;
    text-align: center;
}

.text-container {
    display: none;
}

.roboto
{
    cursor:pointer;
}

@media screen and (min-width: 780px) {
    
    .header {
        position: relative;
    }

    .header img {
        width: 300px;
    }

    .header p {
        height: 20px;
        color: orangered;
        text-align: center;
        padding: 10px;
        text-decoration: none;
        font-size: 15px;
        z-index: 2;
    }

    .top-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
    }

    .bot-header {
        width: 100%;
    }
    
    .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        width: 100%;
    }

    .container-bars {
        display: none;
    }

    .header p:hover {
        color: white;
    }
}

