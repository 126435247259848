
.Roofvent-container {

  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.vent {
  width: 10px;
  height: 10px;
  background-color: rgb(43, 43, 43);
  display: flex;
  align-items: center;
  justify-content: center;
  transition : background-color 5s;
}

.circle {
  width: 9px;
  height: 9px;
  background-color: rgb(33, 33, 33);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition : background-color 5s;
}

.ceiling-fan {
  background-color: rgb(179, 179, 179);
  height: 2px;
  width: 2px;
  border-radius: 50%;
  /* margin: 80px; */
  position: relative;
  transform-origin: center;
  transition : background-color 5s;
}

.spin
{
  animation : spin 0.5s ease-in-out infinite forwards;
}


.ceiling-fan::before {
  content: "";
  position: absolute;
  background-color: white;
  height: 2px;
  width: 2px;
  border-radius: 50% 50% 50% 50% / 85% 85% 15% 15%;
  top: 3px;
  left: 0px;
}

.ceiling-fan::after {
  content: "";
  position: absolute;
  background-color: white;
  height: 2px;
  width: 2px;
  border-radius: 50% 50% 50% 50% / 15% 15% 85% 85%;
  top: -3px;
  left: 0px;
}

.RoofVent-leds {
	width: 10px;
	height: 12px;
	display: flex;
	flex-direction: row;
  justify-content:space-between;
  align-items: flex-end;
}

.led{
  width: 2px;
  height: 2px;
  background-color: rgb(26, 26, 26);
}

.blinker{
  animation-name: blinkay;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes blinkay
{
  0%{opacity:0};
  49%{opacity:0};
  50%{opacity:1};
  99%{opacity:1};
}

.cold
{
  background-color: rgb(0, 247, 255);
  box-shadow: 0 0 10px 5px rgb(0, 247, 255);
}

.hot
{
  background-color: orangered;
  box-shadow: 0 0 10px 5px orangered;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
  0% {
    transform: rotate(0deg);
  }
}
