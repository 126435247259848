.container-clouds {
  bottom: -100%;
  width: auto;
  height: auto;
  left: 0%;
  transition: bottom 2s;
  position: absolute; 
  grid-row: 2;

}

.clouds {
  height: 470px;
  width: 100%;
  position: absolute;
  bottom: 20%;
  z-index: 1;
  transition: filter 8s;
}

.container-clouds.active {
  width: auto;
  height: auto;
  bottom: -5%;
  left: 0%;
}


.cloud {
  border-radius: 50%;
  position: absolute;
  background-color: #ffffff;
  box-shadow: #ffffff 65px -15px 0 -5px, #ffffff 25px -25px, #ffffff 30px 10px,
    #ffffff 60px 15px 0 -10px, #ffffff 85px 5px 0 -5px;
  opacity: 0.4;
  left: -200px;
  transition : box-shadow 5s, background-color 5s;
}

.cloud.active {
  animation-name: moveclouds;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes moveclouds {
  0% {
    left: -20vw;
  }
  100% {
    left: 100vw;
  }
}
