.wirelessContainer
{
    height: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.wireless
{
    height: 20px;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;
    transform-origin: bottom center;
}

.curve1
{
    width: 2px;
    height: 2px;
    border-radius: 50%/2px 2px 0 0;
    border-color: orangered transparent transparent transparent;
    border-top : solid 2px orangered ;
    opacity: 0;
}

.curve2
{
    width: 8px;
    height: 3px;
    border-radius: 50%/3px 3px 0 0;
    border-color: orangered transparent transparent transparent;
    border-top : solid 2px orangered ;
    position: relative;
    top:2px;
    opacity: 0;



}

.curve3
{
    width: 12px;
    height: 5px;
    border-radius: 50%/6px 6px 0 0;
    border-color: orangered transparent transparent transparent;
    border-top : solid 2px orangered ;
    position: relative;
    top:6px;
    opacity: 0;


}

.fade
{
    animation-name: fade;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: 2;
    animation-fill-mode: backwards;
}

@keyframes fade
{
    0%{opacity : 0}
    100%{opacity : 1}
}