.elsysArea {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 80px;
	height: 110px;
	position: absolute;
	left: 50%;
	transition: filter 4s;
	z-index: 2;
	transform: translate(-110%,0%);
}

.elsysOffice{
  	background-color: grey;
	width: 50px;
	height: 100px;
	z-index: 2;
	transition : filter 4s;
}


#ieh {
	width: 50px;
	height: 60px;
	transition : filter 5s;
	z-index:1;
}

.darkFilter {
	transition: filter 5s;
}

.yActive{
	animation-name : radiate;
	animation-duration: 500ms;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	animation-iteration-count: 4;
}

/* .yActive{
	animation-name : radiate2;
	animation-duration: 500ms;
	animation-timing-function: linear;
	animation-fill-mode: backwards;
	animation-iteration-count: 4;
} */

@media screen and (min-width: 780px) {
	.elsysArea {
		transform: translate(-330%,0%);
	}
}

@keyframes radiate
{
	0%{filter:drop-shadow(0px 0px 0px orange);}
	100%{filter:drop-shadow(0px 0px 2px orange);}
}
